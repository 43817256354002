import { ApiPromise, TenantFilterInterface, TenantOrgAdminInterface, TenantPayloadInterface } from '@/types';

import { HTTP } from '@/utils';

export default class AdminService {
  static all_tenants_resource = 'tenants';
  static tenant_resource = 'tenant';
  static quickbooks_resource = 'quickbooks';

  static getTenants(payload: TenantFilterInterface): ApiPromise {
    let filter = '';

    if (payload) {
      filter = `?${Object.keys(payload)
        .map((item) => `${item}=${payload[item as keyof TenantFilterInterface]}`)
        .join('&')}`;
    }

    return HTTP.get(`${this.all_tenants_resource}${filter}`);
  }

  static getTenant(id: number): ApiPromise {
    return HTTP.get(`${this.tenant_resource}/${id}`);
  }

  static deleteTenant(id: number): ApiPromise {
    return HTTP.delete(`${this.tenant_resource}/${id}`);
  }

  static restoreTenant(id: number): ApiPromise {
    return HTTP.get(`${this.tenant_resource}/restore/${id}`);
  }

  static saveTenant(tenant: TenantOrgAdminInterface) {
    return HTTP.post(`${this.tenant_resource}`, tenant);
  }

  static updateTenant(payload: TenantPayloadInterface) {
    return HTTP.put(`${this.tenant_resource}/${payload.tenant.id}`, payload);
  }

  static updateTenantLocation(payload: TenantPayloadInterface) {
    return HTTP.put(`${this.tenant_resource}/location/${payload.tenant.id}`, payload);
  }

  static switchTenant(tenant: number): ApiPromise {
    return HTTP.post(`${this.tenant_resource}/${tenant}/switch-tenant`);
  }

  static leaveTenant(tenant: number): ApiPromise {
    return HTTP.post(`${this.tenant_resource}/${tenant}/leave-tenant`);
  }

  static getQuickbooksAPI(): ApiPromise {
    return HTTP.get(`${this.quickbooks_resource}/auth/url`);
  }

  static setQuickbooksAuthParams(payload: string) {
    return HTTP.get(`${this.quickbooks_resource}/auth/redirect${payload}`);
  }

  static revokeQuickbooks() {
    return HTTP.get(`${this.quickbooks_resource}/auth/revoke`);
  }

  static tokenExists() {
    return HTTP.get(`${this.quickbooks_resource}/auth/token-exists`);
  }

  static uploadRSMFile(tenant: number, document: FormData): ApiPromise {
    return HTTP.post(`${this.tenant_resource}/${tenant}/rsm/upload-file`, document);
  }
}
