import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

export default class PublisherService {
  static resource = 'publishers';
  static useMock = true;

  private static mockData = {
    publishers: [
      {
        id: 1,
        title: 'Publisher One',
        created_at: '2025-01-01T00:00:00.000Z',
        total_assessments: 5,
        description: 'Main educational publisher focused on K-12 content',
        contact_email: 'contact@publisher-one.com',
        status: 'active',
      },
      {
        id: 2,
        title: 'Publisher Two',
        created_at: '2025-01-02T00:00:00.000Z',
        total_assessments: 3,
        description: 'Specialized in early childhood education materials',
        contact_email: 'info@publisher-two.com',
        status: 'active',
      },
      {
        id: 3,
        title: 'Publisher Three',
        created_at: '2025-01-03T00:00:00.000Z',
        total_assessments: 7,
        description: 'Focus on special education assessments',
        contact_email: 'support@publisher-three.com',
        status: 'active',
      },
    ],
  };

  private static getMockResponse<T>(data: T): AxiosResponse {
    return {
      data,
      status: 200,
      statusText: 'OK',
      headers: {
        'Content-Type': 'application/json',
      },
      config: {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as InternalAxiosRequestConfig,
    };
  }

  static getPublishers(): ApiPromise {
    if (this.useMock) {
      return Promise.resolve(
        this.getMockResponse({
          data: this.mockData.publishers,
          meta: { total: this.mockData.publishers.length },
        }),
      );
    }
    return HTTP.get(this.resource);
  }

  static createPublisher(payload: { title: string }): ApiPromise {
    if (this.useMock) {
      return Promise.resolve(
        this.getMockResponse({
          data: this.mockData.publishers[0],
          meta: { total: this.mockData.publishers.length },
        }),
      );
    }
    return HTTP.post(this.resource, payload);
  }

  static updatePublisher(id: number, payload: { title: string }): ApiPromise {
    if (this.useMock) {
      return Promise.resolve(
        this.getMockResponse({
          data: this.mockData.publishers[0],
          meta: { total: this.mockData.publishers.length },
        }),
      );
    }
    return HTTP.put(`${this.resource}/${id}`, payload);
  }

  static deletePublisher(id: number): ApiPromise {
    if (this.useMock) {
      return Promise.resolve(
        this.getMockResponse({
          data: {},
          meta: { total: this.mockData.publishers.length - 1 },
        }),
      );
    }
    return HTTP.delete(`${this.resource}/${id}`);
  }

  static getPublisher(id: number): ApiPromise {
    if (this.useMock) {
      const publisher = this.mockData.publishers.find((p) => p.id === id);
      return Promise.resolve(
        this.getMockResponse({
          data: publisher,
          meta: {},
        }),
      );
    }
    return HTTP.get(`${this.resource}/${id}`);
  }
}
