import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { UtilizationService } from '@/services';
import { useDateTime, useRouting } from '@/composables';
import { useOrganizationStore, useCustomerSuccessPartnerStore } from '@/store';
import {
  UtilizationAnalyticsInterface,
  SOWReportingExportInterface,
  SOWReportingExportResponseInterface,
  SOWReportingTableInterface,
  SOWReportingTotalsInterface,
  SOWReportingSPTableInterface,
  TimeRatioReportingExportInterface,
  TimeRatioReportingExportResponseInterface,
  TimeRatioReportingTableInterface,
  TimeRatioReportingSPTableInterface,
} from '@/types';

export const useUtilizationStore = defineStore('UtilizationStore', () => {
  const customerSuccessPartnerStore = useCustomerSuccessPartnerStore();
  const organizationStore = useOrganizationStore();
  const $routing = useRouting();

  const loading = ref<boolean>(false);
  const loadingServiceProviderData = ref<boolean>(false);
  const serviceProviderSOWTableData = ref<SOWReportingSPTableInterface[]>([]);
  const serviceProviderTimeRatioTableData = ref<TimeRatioReportingSPTableInterface[]>([]);
  const sOWExportData = ref<SOWReportingExportInterface[]>([]);
  const sOWTableData = ref<SOWReportingTableInterface[]>([]);
  const sOWTableTotals = ref<SOWReportingTotalsInterface>();
  const timeRatioExportData = ref<TimeRatioReportingExportInterface[]>([]);
  const timeRatioTableData = ref<TimeRatioReportingTableInterface[]>([]);
  const sOWFilterDate = ref<string>(useDateTime().formatDateForApi(useDateTime().getMostRecentSunday(), true));
  const timeRatioFilterDate = ref<string>(useDateTime().formatDateForApi(useDateTime().getMostRecentSunday(), true));
  const serviceProviderSOWFilterDate = ref<string>(
    useDateTime().formatDateForApi(useDateTime().getMostRecentSunday(), true),
  );
  const serviceProviderTimeRatioFilterDate = ref<string>(
    useDateTime().formatDateForApi(useDateTime().getMostRecentSunday(), true),
  );
  const filteredOrganizations = ref<string[]>([]);
  const selectedServiceProviderId = ref<string>('');

  const getUtilizationDashboardWidgetData = async () => {
    loading.value = true;

    const response = await UtilizationService.getUtilizationWidgetData({
      date: useDateTime().formatDateForApi(useDateTime().getMostRecentSunday()),
      organizations: filteredOrganizations.value,
    });
    sOWTableData.value = generateSOWData(response.data.data);
    timeRatioTableData.value = generateTimeRatioData(response.data.data);
    sOWTableTotals.value = response.data.totals;
    loading.value = false;
  };

  const getServiceProviderTimeUsageWidgetData = async (serviceProviderId: number) => {
    loadingServiceProviderData.value = true;
    selectedServiceProviderId.value = serviceProviderId.toString();

    const response = await UtilizationService.getUtilizationData({
      date: useDateTime().formatDateForApi(useDateTime().getMostRecentSunday()),
      clinicians: [serviceProviderId.toString()],
      organizations: filteredOrganizations.value,
    });
    serviceProviderSOWTableData.value = generateServiceProviderSOWData(response.data.data);
    serviceProviderTimeRatioTableData.value = generateServiceProviderTimeRatioData(response.data.data);
    loadingServiceProviderData.value = false;
  };

  const getSOWReportingExportData = async () => {
    const response = await UtilizationService.getUtilizationData({
      date: sOWFilterDate.value,
      organizations: filteredOrganizations.value,
    });
    sOWExportData.value = generateSOWExportData(response.data.data);
  };

  const getTimeRatioExportData = async () => {
    const response = await UtilizationService.getUtilizationData({
      date: timeRatioFilterDate.value,
      organizations: filteredOrganizations.value,
    });
    timeRatioExportData.value = generateTimeRatioExportData(response.data.data);
  };

  const updateSOWData = async () => {
    const response = await UtilizationService.getUtilizationWidgetData({
      date: sOWFilterDate.value,
      organizations: filteredOrganizations.value,
    });
    sOWTableData.value = generateSOWData(response.data.data);
    sOWTableTotals.value = response.data.totals;
  };

  const updateServiceProviderSOWData = async () => {
    loadingServiceProviderData.value = true;
    const response = await UtilizationService.getUtilizationData({
      date: serviceProviderSOWFilterDate.value,
      clinicians: [selectedServiceProviderId.value],
      organizations: filteredOrganizations.value,
    });
    serviceProviderSOWTableData.value = generateServiceProviderSOWData(response.data.data);
    loadingServiceProviderData.value = false;
  };

  const updateTimeRatioData = async () => {
    const response = await UtilizationService.getUtilizationWidgetData({
      date: timeRatioFilterDate.value,
      organizations: filteredOrganizations.value,
    });
    timeRatioTableData.value = generateTimeRatioData(response.data.data);
  };

  const updateServiceProviderTimeRatioData = async () => {
    loadingServiceProviderData.value = true;
    const response = await UtilizationService.getUtilizationData({
      date: serviceProviderTimeRatioFilterDate.value,
      clinicians: [selectedServiceProviderId.value],
      organizations: filteredOrganizations.value,
    });
    serviceProviderTimeRatioTableData.value = generateServiceProviderTimeRatioData(response.data.data);
    loadingServiceProviderData.value = false;
  };

  const generateSOWData = (data: UtilizationAnalyticsInterface[]): SOWReportingTableInterface[] => {
    return data.map(({ clinician, organization, billed_hours_diff, billed_hours_percent }) => {
      return {
        clinician: clinician,
        organization: organization.name as string,
        billed_hours_diff: stripCommas(billed_hours_diff),
        billed_hours_percent: billed_hours_percent === 'N/A' ? 0 : Number(billed_hours_percent),
      };
    });
  };

  const generateSOWExportData = (data: SOWReportingExportResponseInterface[]): SOWReportingExportInterface[] => {
    return data
      .map(
        ({
          clinician,
          organization,
          contract,
          contract_type,
          sow_hours,
          weeks,
          billed_hours_total_wo_eval,
          eval_hours,
          billed_hours_adjusted_total,
          billed_hours_diff,
          billed_hours_percent,
          billed_hours_average,
        }) => {
          return {
            'Service Provider': clinician.display_name || '',
            Organization: `"${organization.name?.replace(/"/g, '""').replace(/#/g, '')}"`, // Escape quotes and enclose in quotes
            Contract: contract.quote_number,
            Type: contract_type,
            'SOW Hrs/Wk': stripCommas(sow_hours),
            Weeks: weeks,
            'Hrs (Non-Eval)': stripCommas(billed_hours_total_wo_eval),
            'Eval Hours': stripCommas(eval_hours),
            'Total Billed Hrs': stripCommas(billed_hours_adjusted_total),
            'Hrs +/-': stripCommas(billed_hours_diff),
            '%': billed_hours_percent === 'N/A' ? 0 : stripCommas(billed_hours_percent),
            'Avg Hrs/Wk (prior 3 mths)': billed_hours_average === 'N/A' ? 0 : stripCommas(billed_hours_average),
          };
        },
      )
      .sort((a, b) => parseFloat(String(b['%'])) - parseFloat(String(a['%'])));
  };

  const generateServiceProviderSOWData = (data: UtilizationAnalyticsInterface[]): SOWReportingSPTableInterface[] => {
    return data.map(
      ({
        organization,
        contract,
        contract_id,
        contract_type,
        sow_hours,
        weeks,
        billed_hours_total_wo_eval,
        eval_hours,
        billed_hours_adjusted_total,
        billed_hours_diff,
        billed_hours_percent,
        billed_hours_average,
      }) => {
        return {
          organization: organization.name as string,
          contract_id,
          contract_quote_number: contract.quote_number,
          contract_type,
          sow_hours: Number(sow_hours),
          weeks,
          billed_hours_total_wo_eval: stripCommas(billed_hours_total_wo_eval),
          eval_hours: stripCommas(eval_hours),
          billed_hours_total: stripCommas(billed_hours_adjusted_total),
          billed_hours_diff: stripCommas(billed_hours_diff),
          billed_hours_percent: billed_hours_percent === 'N/A' ? 0 : stripCommas(billed_hours_percent),
          billed_hours_average: billed_hours_average === 'N/A' ? 0 : stripCommas(billed_hours_average),
        };
      },
    );
  };

  const generateTimeRatioData = (data: UtilizationAnalyticsInterface[]): TimeRatioReportingTableInterface[] => {
    return data.map(({ clinician, organization, direct_percent, indirect_percent, eval_percent }) => {
      return {
        clinician: clinician,
        organization: organization.name as string,
        direct_percent: Number(direct_percent),
        indirect_percent: Number(indirect_percent),
        eval_percent: Number(eval_percent),
      };
    });
  };

  const generateTimeRatioExportData = (
    data: TimeRatioReportingExportResponseInterface[],
  ): TimeRatioReportingExportInterface[] => {
    return data
      .map(
        ({
          clinician,
          organization,
          contract_type,
          weeks,
          direct_hours,
          direct_percent,
          indirect_hours,
          indirect_percent,
          eval_hours,
          eval_percent,
        }) => {
          return {
            'Service Provider': clinician.display_name || '',
            Organization: `"${organization.name?.replace(/"/g, '""').replace(/#/g, '')}"`,
            Type: contract_type,
            Weeks: weeks,
            'Billed Direct Time Hrs': stripCommas(direct_hours),
            'Billed Direct Time %': Number(direct_percent),
            'Billed Indirect Time Hrs': stripCommas(indirect_hours),
            'Billed Indirect Time %': Number(indirect_percent),
            'Billed Evaluation Time Hrs': stripCommas(eval_hours),
            'Billed Evaluation Time %': Number(eval_percent),
          };
        },
      )
      .sort(
        (a, b) => parseFloat(String(b['Billed Indirect Time %'])) - parseFloat(String(a['Billed Indirect Time %'])),
      );
  };

  const generateServiceProviderTimeRatioData = (
    data: UtilizationAnalyticsInterface[],
  ): TimeRatioReportingSPTableInterface[] => {
    return data.map(
      ({
        organization,
        contract,
        contract_id,
        contract_type,
        weeks,
        direct_hours,
        direct_percent,
        indirect_hours,
        indirect_percent,
        eval_hours,
        eval_percent,
      }) => {
        return {
          organization: organization.name as string,
          contract_id,
          contract_quote_number: contract.quote_number,
          contract_type,
          weeks,
          direct_hours: stripCommas(direct_hours),
          direct_percent: Number(direct_percent),
          indirect_hours: stripCommas(indirect_hours),
          indirect_percent: Number(indirect_percent),
          eval_hours: stripCommas(eval_hours),
          eval_percent: Number(eval_percent),
        };
      },
    );
  };

  const updateSOWFilterDate = async (date: Date) => {
    sOWFilterDate.value = useDateTime().formatDateForApi(date, true);
    await updateSOWData();
  };

  const updateServiceProviderSOWFilterDate = async (date: Date) => {
    serviceProviderSOWFilterDate.value = useDateTime().formatDateForApi(date, true);
    await updateServiceProviderSOWData();
  };

  const updateTimeRatioFilterDate = async (date: Date) => {
    timeRatioFilterDate.value = useDateTime().formatDateForApi(date, true);
    await updateTimeRatioData();
  };

  const updateServiceProviderTimeRatioFilterDate = async (date: Date) => {
    serviceProviderTimeRatioFilterDate.value = useDateTime().formatDateForApi(date, true);
    await updateServiceProviderTimeRatioData();
  };

  const updateDataBasedOnCurrentRoute = async () => {
    if ($routing.route.path === '/dashboard/overview') {
      await updateSOWData();
      await updateTimeRatioData();
    } else if ($routing.route.path === '/dashboard/time-usage') {
      await updateServiceProviderSOWData();
      await updateServiceProviderTimeRatioData();
    }
  };

  const stripCommas = (str: string): number => {
    return Number(str.replace(/,/g, ''));
  };

  // Watches for changes to the CSP or Org filter on the dashboard page and filters down the utilization
  // widgets according to the selected/associated orgs
  watch(
    [
      () => customerSuccessPartnerStore.selectedDashboardCustomerSuccessPartners,
      () => organizationStore.organizationsIdsSelected,
    ],
    async () => {
      const selectedOrgIds = new Set(organizationStore.organizationsIdsSelected.map((org) => org.toString()));
      const selectCSPOrgIds = new Set(
        customerSuccessPartnerStore.selectedDashboardCustomerSuccessPartners.flatMap((csp) =>
          csp.organizations.map((org) => org.toString()),
        ),
      );

      filteredOrganizations.value = Array.from(selectedOrgIds).concat(Array.from(selectCSPOrgIds));
      await updateDataBasedOnCurrentRoute();
    },
  );

  return {
    loading,
    loadingServiceProviderData,
    sOWExportData,
    sOWTableData,
    sOWTableTotals,
    serviceProviderSOWTableData,
    timeRatioExportData,
    timeRatioTableData,
    serviceProviderTimeRatioTableData,
    getServiceProviderTimeUsageWidgetData,
    getSOWReportingExportData,
    getTimeRatioExportData,
    getUtilizationDashboardWidgetData,
    selectedServiceProviderId,
    updateSOWFilterDate,
    updateServiceProviderSOWFilterDate,
    updateTimeRatioFilterDate,
    updateServiceProviderTimeRatioFilterDate,
    serviceProviderSOWFilterDate,
    serviceProviderTimeRatioFilterDate,
  };
});
