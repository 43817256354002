import { defineStore } from 'pinia';
import { Component } from 'vue';
import { StoreInterface } from '@/types';
import { Publishers, Assessments } from '@/views/Admin/AssessmentManagement/components';

interface AssessmentManagementStateInterface extends StoreInterface {
  selectedAssessmentRoutes: AssessmentRoute[];
}

interface AssessmentRoute {
  to: string;
  label: string;
  component: Component;
}

export default defineStore('AssessmentManagementStore', {
  state: (): AssessmentManagementStateInterface => ({
    message: '',
    error: false,
    loading: false,
    selectedAssessmentRoutes: [
      {
        to: '/admin/assessment-management/publishers',
        label: 'Publishers',
        component: Publishers,
      },
      {
        to: '/admin/assessment-management/assessments',
        label: 'Assessments',
        component: Assessments,
      },
    ],
  }),

  actions: {
    load() {
      this.error = false;
      this.message = '';
      this.loading = true;
    },

    catchError(e: Error) {
      this.error = 'error';
      throw e;
    },

    loaded(msg = '') {
      if (!this.error) {
        this.message = msg;
        this.loading = false;
      }
    },
  },
});
