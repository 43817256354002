import { defineStore } from 'pinia';
import { PublisherService } from '@/services';

interface PublisherInterface {
  id: number;
  title: string;
  created_at: string;
}

interface PublisherStoreStateInterface {
  publishers: {
    loading: boolean;
    data: PublisherInterface[];
    total: number;
  };
}

export default defineStore('PublisherStore', {
  state: (): PublisherStoreStateInterface => ({
    publishers: {
      loading: false,
      data: [],
      total: 0,
    },
  }),

  actions: {
    async getPublishers() {
      this.publishers.loading = true;
      const response = await PublisherService.getPublishers();
      this.publishers.data = response.data.data;
      this.publishers.total = response.data.meta.total;
      this.publishers.loading = false;
    },

    async getPublisher(id: number) {
      const response = await PublisherService.getPublisher(id);
      return response.data;
    },

    async createPublisher(title: string) {
      await PublisherService.createPublisher({ title });
      this.getPublishers();
    },

    async updatePublisher(id: number, title: string) {
      await PublisherService.updatePublisher(id, { title });
      this.getPublishers();
    },

    async deletePublisher(id: number) {
      await PublisherService.deletePublisher(id);
      this.getPublishers();
    },
  },
});
